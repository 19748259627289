import React, { useEffect, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent, Table,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap"

import classnames from "classnames"
import { Link } from "react-router-dom"

// XPS API Imports
import { _xps } from "@g7dao/public-project-webhooks";
import {Checkbox} from "@material-ui/core";

const CreateTask = () => {

  //meta title
  document.title="XPS | Task Dashboard";

  const [achievementList, setAchievementList] = useState([]);
  const [activeTab, setactiveTab] = useState(1)
  const [getIntegrationTracker, setIntegrationTracker] = useState(0)
  const [passedSteps, setPassedSteps] = useState([1])
  const [xpsResponse, setXPSResponse] = useState({})
  const [getJobs, setJobs] = useState([])
  const [integrationsResponse, setIntegrationsResponse] = useState([])
  const [getQuestsResponse, setGetQuestsResponse] = useState([]);
  const [getTask, setTask] = useState({
    name: "",
    description: "",
    instructions: "",
    quest: "",
    rankEarned: 0.01,
    xpEarned: 500,
    achievement: "",
    required: 0,
    order: 0,
  });

  useEffect(() => {
    getQuests();
    getAchievements();
  }, []);

  const getQuests = () => {
    const resp = _xps.getAllQuests();
    resp
      .then((r) => {
        if (r.status === 201 || r.status === 200) {
          return r.json();
        }
      })
      .then((r) => {
        console.log(r.data)
        setGetQuestsResponse(r.data)
      })
      .catch(() => {
        alert("get tasks error");
      });
  };

  const getAchievements = () => {
    const resp = _xps.getAchievements();
    resp
      .then((r) => {
        if (r.status === 201 || r.status === 200) {
          return r.json();
        }
      })
      .then((r) => {
        setAchievementList(r.data)
        //console.log(r.data)
      })
      .catch(() => {
        alert("get achievement error");
      });
  };

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }

      if (tab === 3 && getIntegrationTracker === 0) {
        setIntegrationTracker(0)

        // this will populate tab 4
        getIntegrationsAndJobs()
      }

      if (tab === 4) {
        processFormResults()
      }
    }
  }

  const getIntegrationsAndJobs = () => {
    const resp = _xps.getAllIntegrations();
    resp
      .then((r) => {
        if (r.status === 200) {
          return r.json();
        }
      })
      .then((r) => {setIntegrationsResponse(r.data)})
      .catch(() => {
        alert("Get Integrations Error");
      });
  };

  const integrations = () => {
    return (
      <>
        {integrationsResponse.map((row, i) => {
          return (
            <tr key={i}>
              <td className="text-start">
                {row["integration"]["name"]}
              </td>
              <td>
                <Table className="table-sm">
                  <tbody>
                    {row["watchers"].map((row, i) => {
                      return (
                        <tr key={i}>
                          <td className="text-start">
                            <div>{row["name"]}</div>
                            <div>XP: {row["xp"]}, Rank: {row["rank"]}, Repeat: {row["iterations"]}</div>
                            <div>{JSON.stringify(row["expression"])}</div>
                          </td>
                          <td><Checkbox value={row["id"]} onChange={(e) => processWatchers(e)} /></td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </td>
            </tr>
          );
        })}
      </>
    )
  }

  const processFormResults = () => {
    const resp = _xps.createTask(getTask);
    resp
      .then((r) => {
        if (r.status === 201 || r.status === 200) {
          return r.json();
        }
      })
      .then((r) => {
        setXPSResponse(r.data)
      })
      .catch(() => {
        alert("Form Submission Error");
      });
  };

  const processWatchers = (event) => {

    if(event.target.checked){
      let _p = {
        taskId: xpsResponse["id"],
        workerId: event.target.value,
        type: "",
        iterations: 0,
        xp: 0,
        rank: 0,
        expression: {},
      }

      {integrationsResponse.map((row, i) => {
        {row["watchers"].map((row, i) => {
          if(row["id"] === event.target.value){
            _p["type"] = row["type"];
            _p["iterations"] = row["iterations"];
            _p["xp"] = row["xp"];
            _p["rank"] = row["rank"];
            _p["expression"] = row["expression"];
          }
        })}
      })}

      const resp = _xps.createWorkerJob(_p);
      resp
        .then((r) => {
          if (r.status === 201 || r.status === 200) {
            return r.json();
          }
        })
        .then((r) => {
          _p["jobId"] = r.data["id"]
          setJobs(oldArray => [...oldArray, _p])
          alert("Job Added");
        })
        .catch(() => {
          alert("Form Submission Error");
        });

    } else {
      const newArray = []
      for(let i in getJobs) {
        let _k = Number(i)
        const _j = getJobs[_k]
        if(_j["workerId"] === event.target.value) {
          // delete job and don't add to new array
          const resp = _xps.deleteWorkerJob(_j["jobId"]);
          resp
            .then((r) => {
              if (r.status === 201 || r.status === 200) {
                return r.json();
              }
            })
            .then((r) => {
              alert("Job Removed");
            })
            .catch(() => {
              alert("Form Submission Error");
            });
        } else {
          newArray.push(_j)
        }
      }
      // create new array
      setJobs(newArray)
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <div className="text-wrap">
                {JSON.stringify(getTask)}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">Create Task Wizard</h4>
              <div className="wizard clearfix">
                <div className="steps clearfix">
                  <ul>
                    <NavItem
                      className={classnames({ current: activeTab === 1 })}
                    >
                      <NavLink
                        className={classnames({ current: activeTab === 1 })}
                        onClick={() => {
                          setactiveTab(1)
                        }}
                        disabled={!(passedSteps || []).includes(1)}
                      >
                        <span className="number">1.</span> Task Details
                      </NavLink>
                    </NavItem>
                    <NavItem
                      className={classnames({ current: activeTab === 2 })}
                    >
                      <NavLink
                        className={classnames({ active: activeTab === 2 })}
                        onClick={() => {
                          setactiveTab(2)
                        }}
                        disabled={!(passedSteps || []).includes(2)}
                      >
                        <span className="number">2.</span> Task Requirements
                      </NavLink>
                    </NavItem>
                    <NavItem
                      className={classnames({ current: activeTab === 3 })}
                    >
                      <NavLink
                        className={classnames({ active: activeTab === 3 })}
                        onClick={() => {
                          setactiveTab(3)
                        }}
                        disabled={!(passedSteps || []).includes(3)}
                      >
                        <span className="number">3.</span> Achievement Details
                      </NavLink>
                    </NavItem>
                    <NavItem
                      className={classnames({ current: activeTab === 4 })}
                    >
                      <NavLink
                        className={classnames({ active: activeTab === 4 })}
                        onClick={() => {
                          setactiveTab(4)
                        }}
                        disabled={!(passedSteps || []).includes(4)}
                      >
                        <span className="number">4.</span> Confirm Detail
                      </NavLink>
                    </NavItem>
                  </ul>
                </div>
                <div className="content clearfix">
                  <TabContent activeTab={activeTab} className="body">
                    <TabPane tabId={1}>
                      <Form>
                        <Row>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Task Name
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter a memorable Task Name"
                                value={getTask.name || ""}
                                onChange={(e) => {
                                  setTask((prevState) => {
                                    return { ...prevState, name: e.target.value };
                                  });
                                }}
                              />
                            </div>
                          </Col>
                          <Col lg="8">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input2">
                                Task Description
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="basicpill-lastname-input2"
                                placeholder="Enter a short Task Description"
                                value={getTask.description || ""}
                                onChange={(e) => {
                                  setTask((prevState) => {
                                    return { ...prevState, description: e.target.value };
                                  });
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="4">
                           <div className="mb-3">
                              <Label>Assign Task to Quest</Label>
                              <select
                                className="form-select"
                                onChange={(e) => {
                                  setTask((prevState) => {
                                    return { ...prevState, quest: e.target.value };
                                  });
                                }}
                              >
                                <option defaultValue>
                                  Select a Quest
                                </option>
                                {getQuestsResponse.map((q) => <option value={q.id}>{q.name}</option>)}
                              </select>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <div className="mb-3">
                              <Label for="basicpill-address-input1">
                                Detailed Instructions
                              </Label>
                              <textarea
                                id="basicpill-address-input1"
                                className="form-control"
                                rows="4"
                                placeholder="Enter Detailed Task Instructions"
                                value={getTask.instructions || ""}
                                onChange={(e) => {
                                  setTask((prevState) => {
                                    return { ...prevState, instructions: e.target.value };
                                  });
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </TabPane>
                    <TabPane tabId={2}>
                      <div>
                        <Form>
                          <Row>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-phoneno-input3">
                                  Earned Rank
                                    <Link to="#" className="m-1" id="e-r">
                                      <i className="fas fa-comment-dots"></i>
                                      <UncontrolledTooltip placement="top" target="e-r">
                                        rank earned by completing task
                                      </UncontrolledTooltip>
                                    </Link>
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-phoneno-input3"
                                  placeholder="0.1.0"
                                  value={getTask.rankEarned || 0}
                                  onChange={(e) => {
                                    setTask((prevState) => {
                                      return { ...prevState, rankEarned: +e.target.value };
                                    });
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-email-input4">
                                  Earned XP
                                    <Link to="#" className="m-1" id="e-xp">
                                      <i className="fas fa-comment-dots"></i>
                                      <UncontrolledTooltip placement="top" target="e-xp">
                                        XP earned by completing task
                                      </UncontrolledTooltip>
                                    </Link>
                                </Label>
                                <Input
                                  type="email"
                                  className="form-control"
                                  id="basicpill-email-input4"
                                  placeholder="100"
                                  value={getTask.xpEarned || 0}
                                  onChange={(e) => {
                                    setTask((prevState) => {
                                      return { ...prevState, xpEarned: +e.target.value };
                                    });
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-cstno-input7">
                                  Task Required?
                                </Label>
                                <select
                                  className="form-select"
                                  onChange={(e) => {
                                    setTask((prevState) => {
                                      return { ...prevState, required: +e.target.value };
                                    });
                                  }}
                                >
                                  <option defaultValue>
                                    Is this task required?
                                  </option>
                                  <option value="1">Yes</option>
                                  <option value="0">No</option>
                                </select>
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-servicetax-input8">
                                  What order is this Task?
                                  <Link to="#" className="m-1" id="bitcoin">
                                    <i className="fas fa-comment-dots"></i>
                                    <UncontrolledTooltip placement="top" target="bitcoin">
                                      ensure "ordered" is set to "yes" in the Quest
                                    </UncontrolledTooltip>
                                  </Link>
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-servicetax-input8"
                                  placeholder="0"
                                  value={getTask.order || 0}
                                  onChange={(e) => {
                                    setTask((prevState) => {
                                      return { ...prevState, order: +e.target.value };
                                    });
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </TabPane>
                    <TabPane tabId={3}>
                      <div>
                        <Form>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-vatno-input6">
                                  Achievement Earned
                                </Label>
                                <select
                                  className="form-select"
                                  onChange={(e) => {
                                    setTask((prevState) => {
                                      return { ...prevState, achievement: e.target.value };
                                    });
                                  }}
                                >
                                  <option defaultValue>
                                    Select Achievement
                                  </option>
                                  {achievementList.map((q) => {
                                    if(q.status === 1) {
                                      return (
                                        <option value={q.id}>{q.name}</option>
                                      )
                                    }
                                  })}
                                </select>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </TabPane>
                    <TabPane tabId={4}>
                      <div className="row justify-content-center">
                        <Col lg="6">
                          <div className="text-center">
                            <div className="mb-4">
                              <i className="mdi mdi-check-circle-outline text-success display-4" />
                            </div>
                            <div>
                              <h5>Task has been created successfully</h5>
                              <p>Now, let's add some watchers to the new task.</p>
                              <div className="table-responsive">
                                <Table className="table-sm">
                                  <tbody>
                                    {integrations()}
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                            <div>
                              <h5>Task Submission Details (create response)</h5>
                              <p className="text-muted">
                                {JSON.stringify(xpsResponse)}
                              </p>
                            </div>
                          </div>
                        </Col>
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
                <div className="actions clearfix">
                  <ul>
                    <li
                      className={
                        activeTab === 1 ? "previous disabled" : "previous"
                      }
                    >
                      <Link
                        to="#"
                        onClick={() => {
                          toggleTab(activeTab - 1)
                        }}
                      >
                        Previous
                      </Link>
                    </li>
                    <li
                      className={activeTab === 4 ? "next disabled" : "next"}
                    >
                      <Link
                        to="#"
                        onClick={() => {
                          toggleTab(activeTab + 1)
                        }}
                      >
                        Next
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CreateTask;
