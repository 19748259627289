import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"

// API & Auth Imports
import { _auth } from "@g7dao/public-project-webhooks";

const authToken = _auth.getCookies();

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      // user is not logged in, redirect to login
      if (isAuthProtected && !authToken) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }

      // if user is logged in, redirect public pages to dashboard
      if (!isAuthProtected && authToken) {
        return (
          <Redirect
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any, 
}

export default Authmiddleware;
