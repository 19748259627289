import React, { useEffect, useState, useRef, useMemo } from "react";
import { withRouter, Link } from "react-router-dom";
import TableContainer from "../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  UncontrolledTooltip,
  Input,
} from "reactstrap";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { Name, Numeric } from "./contactlistCol";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

// XPS API Imports
import { _xps, _file } from "@g7dao/public-project-webhooks";

const Index = props => {

  //meta title
  document.title="XPS | Discord";

  const [discordUser, setDiscordUser] = useState({
    name: '',
    description: '',
    rank: 0.01,
    xp: 0,
    rarity: '',
    publish: 0,
    status: 0,
  });
  const [discordUsers, setDiscordUsers] = useState([]);
  const [assetObject, setAssetObject] = useState({});
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "Discord Handle",
        accessor: "discordHandle",
        disableFilters: true,
        isSorted: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Skill",
        accessor: "userType",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "User ID",
        accessor: "userId",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Xp",
        accessor: "xp",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <Numeric {...cellProps} />{" "}
            </>
          );
        },
      },
      {
        Header: "Rank",
        accessor: "rank",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <Numeric {...cellProps} />{" "}
            </>
          );
        },
      },
      {
        Header: "Task",
        accessor: "task",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Numeric {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    getDiscordUsers();
    setIsEdit(false);
  }, []);

  const toggle = () => {
    setModal(!modal);
  };

  const getDiscordUsers = () => {
    const resp = _xps.getAllDiscordBotUsers();
    resp
      .then((r) => {
        if (r.status === 201 || r.status === 200) {
          return r.json();
        }
      })
      .then((r) => {
        setDiscordUsers(r.data)
        console.log(r.data)
      })
      .catch(() => {
        alert("get tasks error");
      });
  };

  const handleUserClicks = () => {
    setDiscordUser({
      name: '',
      description: '',
      rank: 0.01,
      xp: 0,
      rarity: '',
      publish: 0,
      status: 0,
    });
    setIsEdit(false);
    toggle();
  };

  const ErrorSchema = Yup.object().shape({
   name: Yup.string()
     .min(2, 'Too Short!')
     .max(50, 'Too Long!')
     .required('Required'),
   description: Yup.string()
     .min(2, 'Too Short!')
     .max(250, 'Too Long!')
     .required('Required'),
   rank: Yup.string()
     .min(3, 'Too Short!')
     .max(5, 'Too Long!')
     .required('Required'),
   xp: Yup.string()
     .min(1, 'Too Short!')
     .max(5, 'Too Long!')
     .required('Required'),
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Contacts" breadcrumbItem="Discord Users Dashboard" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    name="DiscordUsers"
                    columns={columns}
                    data={discordUsers}
                    isGlobalFilter={true}
                    isAddUserList={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Index);
