import React, { useEffect, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap"

import classnames from "classnames"
import { Link } from "react-router-dom"

// XPS API Imports
import { _xps } from "@g7dao/public-project-webhooks";

const CreatePath = () => {

  //meta title
  document.title="XPS | Path Dashboard";

  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])
  const [xpsResponse, setXPSResponse] = useState({})
  const [getPath, setPath] = useState({
    name: "",
    description: "",
    instructions: "",
    level: "",
    ordered: 0,
    order: 0,
    required: 0,
    xpEarned: "",
    rankEarned: "",
    achievement: "",
    entryXp: "",
    entryRank: "",
    entryAchievement: "",
    exitXp: "",
    exitRank: "",
    exitAchievement: "",
    repeat: 0,
    repeatMax: 0,
    repeatXp: "",
    repeatRank: "",
    repeatDecay: "",
  });

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }

      if (tab === 4) {
        processFormResults()
      }
    }
  }

  const processFormResults = () => {
    const resp = _xps.createPath(getPath);
    resp
      .then((r) => {
        if (r.status === 201 || r.status === 200) {
          return r.json();
        }
      })
      .then((r) => {setXPSResponse(r.data)})
      .catch(() => {
        alert("Form submission error");
      });
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <div className="text-wrap">
                {JSON.stringify(getPath)}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">Create Path Wizard</h4>
              <div className="wizard clearfix">
                <div className="steps clearfix">
                  <ul>
                    <NavItem
                      className={classnames({ current: activeTab === 1 })}
                    >
                      <NavLink
                        className={classnames({ current: activeTab === 1 })}
                        onClick={() => {
                          setactiveTab(1)
                        }}
                        disabled={!(passedSteps || []).includes(1)}
                      >
                        <span className="number">1.</span> Path Details
                      </NavLink>
                    </NavItem>
                    <NavItem
                      className={classnames({ current: activeTab === 2 })}
                    >
                      <NavLink
                        className={classnames({ active: activeTab === 2 })}
                        onClick={() => {
                          setactiveTab(2)
                        }}
                        disabled={!(passedSteps || []).includes(2)}
                      >
                        <span className="number">2.</span> Path Requirements
                      </NavLink>
                    </NavItem>
                    <NavItem
                      className={classnames({ current: activeTab === 3 })}
                    >
                      <NavLink
                        className={classnames({ active: activeTab === 3 })}
                        onClick={() => {
                          setactiveTab(3)
                        }}
                        disabled={!(passedSteps || []).includes(3)}
                      >
                        <span className="number">3.</span> Achievement Details
                      </NavLink>
                    </NavItem>
                    <NavItem
                      className={classnames({ current: activeTab === 4 })}
                    >
                      <NavLink
                        className={classnames({ active: activeTab === 4 })}
                        onClick={() => {
                          setactiveTab(4)
                        }}
                        disabled={!(passedSteps || []).includes(4)}
                      >
                        <span className="number">4.</span> Confirm Detail
                      </NavLink>
                    </NavItem>
                  </ul>
                </div>
                <div className="content clearfix">
                  <TabContent activeTab={activeTab} className="body">
                    <TabPane tabId={1}>
                      <Form>
                        <Row>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Path Name
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter a memorable Path Name"
                                value={getPath.name || ""}
                                onChange={(e) => {
                                  setPath((prevState) => {
                                    return { ...prevState, name: e.target.value };
                                  });
                                }}
                              />
                            </div>
                          </Col>
                          <Col lg="8">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input2">
                                Path Description
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="basicpill-lastname-input2"
                                placeholder="Enter a short Path Description"
                                value={getPath.description || ""}
                                onChange={(e) => {
                                  setPath((prevState) => {
                                    return { ...prevState, description: e.target.value };
                                  });
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="3">
                           <div className="mb-3">
                              <Label>Assign Path to a Level</Label>
                              <select
                                className="form-select"
                                onChange={(e) => {
                                  setPath((prevState) => {
                                    return { ...prevState, level: e.target.value };
                                  });
                                }}
                              >
                                <option defaultValue>
                                  Select a Level
                                </option>
                                <option value="1.0.0">Level 1</option>
                                <option value="2.0.0">Level 2</option>
                                <option value="3.0.0">Level 3</option>
                                <option value="4.0.0">Level 4</option>
                                <option value="5.0.0">Level 5</option>
                                <option value="6.0.0">Level 6</option>
                                <option value="7.0.0">Level 7</option>
                                <option value="8.0.0">Level 8</option>
                                <option value="9.0.0">Level 9</option>
                                <option value="10.0.0">Level 10</option>
                              </select>
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-servicetax-input8">
                                Ordered Quests Required?
                              </Label>
                              <select
                                className="form-select"
                                onChange={(e) => {
                                  setPath((prevState) => {
                                    return { ...prevState, ordered: e.target.value };
                                  });
                                }}
                              >
                                <option defaultValue>
                                  require quests to be completed in order?
                                </option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-servicetax-input8">
                                What order is this Path completed?
                                <Link to="#" className="m-1" id="order-h">
                                  <i class="fas fa-comment-dots"></i>
                                  <UncontrolledTooltip placement="top" target="order-h">
                                    ensure "ordered" is set to "yes" in the Path
                                  </UncontrolledTooltip>
                                </Link>
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="basicpill-servicetax-input8"
                                placeholder="0"
                                value={getPath.order || ""}
                                onChange={(e) => {
                                  setPath((prevState) => {
                                    return { ...prevState, order: e.target.value };
                                  });
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <div className="mb-3">
                              <Label for="basicpill-address-input1">
                                Detailed Instructions
                              </Label>
                              <textarea
                                id="basicpill-address-input1"
                                className="form-control"
                                rows="4"
                                placeholder="Enter Detailed Path Instructions"
                                value={getPath.instructions || ""}
                                onChange={(e) => {
                                  setPath((prevState) => {
                                    return { ...prevState, instructions: e.target.value };
                                  });
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </TabPane>
                    <TabPane tabId={2}>
                      <div>
                        <Form>
                          <Row>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-phoneno-input3">
                                  Min Rank
                                    <Link to="#" className="m-1" id="m-r">
                                      <i class="fas fa-comment-dots"></i>
                                      <UncontrolledTooltip placement="top" target="m-r">
                                        minimum rank required to start Path
                                      </UncontrolledTooltip>
                                    </Link>
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-phoneno-input3"
                                  placeholder="1.0.0"
                                  value={getPath.entryRank || ""}
                                  onChange={(e) => {
                                    setPath((prevState) => {
                                      return { ...prevState, entryRank: e.target.value };
                                    });
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-email-input4">
                                  Min XP
                                    <Link to="#" className="m-1" id="m-xp">
                                      <i class="fas fa-comment-dots"></i>
                                      <UncontrolledTooltip placement="top" target="m-xp">
                                        minimum XP required to start Path
                                      </UncontrolledTooltip>
                                    </Link>
                                </Label>
                                <Input
                                  type="email"
                                  className="form-control"
                                  id="basicpill-email-input4"
                                  placeholder="1000"
                                  value={getPath.entryXp || ""}
                                  onChange={(e) => {
                                    setPath((prevState) => {
                                      return { ...prevState, entryXp: e.target.value };
                                    });
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-phoneno-input3">
                                  Exit Rank
                                    <Link to="#" className="m-1" id="exit-r">
                                      <i class="fas fa-comment-dots"></i>
                                      <UncontrolledTooltip placement="top" target="exit-r">
                                        rank required to complete Path
                                      </UncontrolledTooltip>
                                    </Link>
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-phoneno-input3"
                                  placeholder="0.1.0"
                                  value={getPath.exitRank || ""}
                                  onChange={(e) => {
                                    setPath((prevState) => {
                                      return { ...prevState, exitRank: e.target.value };
                                    });
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-email-input4">
                                  Exit XP
                                    <Link to="#" className="m-1" id="exit-xp">
                                      <i class="fas fa-comment-dots"></i>
                                      <UncontrolledTooltip placement="top" target="exit-xp">
                                        XP required to complete Path
                                      </UncontrolledTooltip>
                                    </Link>
                                </Label>
                                <Input
                                  type="email"
                                  className="form-control"
                                  id="basicpill-email-input4"
                                  placeholder="100"
                                  value={getPath.exitXp || ""}
                                  onChange={(e) => {
                                    setPath((prevState) => {
                                      return { ...prevState, exitXp: e.target.value };
                                    });
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-phoneno-input3">
                                  Earned Rank
                                    <Link to="#" className="m-1" id="e-r">
                                      <i class="fas fa-comment-dots"></i>
                                      <UncontrolledTooltip placement="top" target="e-r">
                                        rank earned by completing Path
                                      </UncontrolledTooltip>
                                    </Link>
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-phoneno-input3"
                                  placeholder="0.1.0"
                                  value={getPath.rankEarned || ""}
                                  onChange={(e) => {
                                    setPath((prevState) => {
                                      return { ...prevState, rankEarned: e.target.value };
                                    });
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-email-input4">
                                  Earned XP
                                    <Link to="#" className="m-1" id="e-xp">
                                      <i class="fas fa-comment-dots"></i>
                                      <UncontrolledTooltip placement="top" target="e-xp">
                                        XP earned by completing Path
                                      </UncontrolledTooltip>
                                    </Link>
                                </Label>
                                <Input
                                  type="email"
                                  className="form-control"
                                  id="basicpill-email-input4"
                                  placeholder="100"
                                  value={getPath.xpEarned || ""}
                                  onChange={(e) => {
                                    setPath((prevState) => {
                                      return { ...prevState, xpEarned: e.target.value };
                                    });
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-cstno-input7">
                                  Path Required?
                                </Label>
                                <select
                                  className="form-select"
                                  onChange={(e) => {
                                    setPath((prevState) => {
                                      return { ...prevState, required: e.target.value };
                                    });
                                  }}
                                >
                                  <option defaultValue>
                                    Is this Path required?
                                  </option>
                                  <option value="1">Yes</option>
                                  <option value="0">No</option>
                                </select>
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-servicetax-input8">
                                  Repeat?
                                </Label>
                                <select
                                  className="form-select"
                                  onChange={(e) => {
                                    setPath((prevState) => {
                                      return { ...prevState, repeat: e.target.value };
                                    });
                                  }}
                                >
                                  <option defaultValue>
                                    Allow Path to be repeated?
                                  </option>
                                  <option value="1">Yes</option>
                                  <option value="0">No</option>
                                </select>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-servicetax-input8">
                                  Repeat Max
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-servicetax-input8"
                                  placeholder="4"
                                  value={getPath.repeatMax || ""}
                                  onChange={(e) => {
                                    setPath((prevState) => {
                                      return { ...prevState, repeatMax: e.target.value };
                                    });
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-servicetax-input8">
                                  Repeat XP Earn
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-servicetax-input8"
                                  placeholder="200"
                                  value={getPath.repeatXp || ""}
                                  onChange={(e) => {
                                    setPath((prevState) => {
                                      return { ...prevState, repeatXp: e.target.value };
                                    });
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-servicetax-input8">
                                  Repeat Rank Earn
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-servicetax-input8"
                                  placeholder="0.2.0"
                                  value={getPath.repeatRank || ""}
                                  onChange={(e) => {
                                    setPath((prevState) => {
                                      return { ...prevState, repeatRank: e.target.value };
                                    });
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg="3">
                             <div className="mb-3">
                                <Label>Repeat Decay</Label>
                                <select
                                  className="form-select"
                                  onChange={(e) => {
                                    setPath((prevState) => {
                                      return { ...prevState, repeatDecay: e.target.value };
                                    });
                                  }}
                                >
                                  <option defaultValue>
                                    Select Calculation Type
                                  </option>
                                  <option value="0">0%</option>
                                  <option value="10">10%</option>
                                  <option value="20">20%</option>
                                  <option value="25">25%</option>
                                </select>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </TabPane>
                    <TabPane tabId={3}>
                      <div>
                        <Form>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-pancard-input5">
                                  Achievement Required to Enter Path
                                    <Link to="#" className="m-1" id="achren">
                                      <i class="fas fa-comment-dots"></i>
                                      <UncontrolledTooltip placement="top" target="achren">
                                        achievement required to start this Path
                                      </UncontrolledTooltip>
                                    </Link>
                                </Label>
                                <select
                                  className="form-select"
                                  onChange={(e) => {
                                    setPath((prevState) => {
                                      return { ...prevState, entryAchievement: e.target.value };
                                    });
                                  }}
                                >
                                  <option defaultValue>
                                    Select Achievement
                                  </option>
                                  <option value="12321488745">Super Awesome Person</option>
                                  <option value="12321445555">Onboarding Champion</option>
                                </select>
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-vatno-input6">
                                  Achievement Earned
                                    <Link to="#" className="m-1" id="ache">
                                      <i class="fas fa-comment-dots"></i>
                                      <UncontrolledTooltip placement="top" target="ache">
                                        achievement earned by completing this task
                                      </UncontrolledTooltip>
                                    </Link>
                                </Label>
                                <select
                                  className="form-select"
                                  onChange={(e) => {
                                    setPath((prevState) => {
                                      return { ...prevState, achievement: e.target.value };
                                    });
                                  }}
                                >
                                  <option defaultValue>
                                    Select Achievement
                                  </option>
                                  <option value="12321488745">Super Awesome Person</option>
                                  <option value="12321445555">Onboarding Champion</option>
                                </select>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-pancard-input5">
                                  Achievement Required to Exit Path
                                    <Link to="#" className="m-1" id="achr">
                                      <i class="fas fa-comment-dots"></i>
                                      <UncontrolledTooltip placement="top" target="achr">
                                        achievement earned by completing this task
                                      </UncontrolledTooltip>
                                    </Link>
                                </Label>
                                <select
                                  className="form-select"
                                  onChange={(e) => {
                                    setPath((prevState) => {
                                      return { ...prevState, exitAchievement: e.target.value };
                                    });
                                  }}
                                >
                                  <option defaultValue>
                                    Select Achievement
                                  </option>
                                  <option value="12321488745">Super Awesome Person</option>
                                  <option value="12321445555">Onboarding Champion</option>
                                </select>
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-vatno-input6">
                                  Achievement Earned (not used)
                                </Label>
                                <select className="form-select">
                                  <option defaultValue>
                                    Select Achievement
                                  </option>
                                  <option value="AE">Super Awesome Person</option>
                                  <option value="VI">Onboarding Champion</option>
                                </select>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-pancard-input5">
                                  Achievement Required (not used)
                                </Label>
                                <select className="form-select">
                                  <option defaultValue>
                                    Select Achievement
                                  </option>
                                  <option value="AE">Super Awesome Person</option>
                                  <option value="VI">Onboarding Champion</option>
                                </select>
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-vatno-input6">
                                  Achievement Earned (not used)
                                </Label>
                                <select className="form-select">
                                  <option defaultValue>
                                    Select Achievement
                                  </option>
                                  <option value="AE">Super Awesome Person</option>
                                  <option value="VI">Onboarding Champion</option>
                                </select>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </TabPane>
                    <TabPane tabId={4}>
                      <div className="row justify-content-center">
                        <Col lg="6">
                          <div className="text-center">
                            <div className="mb-4">
                              <i className="mdi mdi-check-circle-outline text-success display-4" />
                            </div>
                            <div>
                              <h5>Completion Detail</h5>
                              <p className="text-muted">
                                TODO: We will add the requirements needed to complete this Path here
                              </p>
                            </div>
                            <div>
                              <h5>Path Submission Details</h5>
                              <p className="text-muted">
                                {JSON.stringify(xpsResponse)}
                              </p>
                            </div>
                          </div>
                        </Col>
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
                <div className="actions clearfix">
                  <ul>
                    <li
                      className={
                        activeTab === 1 ? "previous disabled" : "previous"
                      }
                    >
                      <Link
                        to="#"
                        onClick={() => {
                          toggleTab(activeTab - 1)
                        }}
                      >
                        Previous
                      </Link>
                    </li>
                    <li
                      className={activeTab === 4 ? "next disabled" : "next"}
                    >
                      <Link
                        to="#"
                        onClick={() => {
                          toggleTab(activeTab + 1)
                        }}
                      >
                        Next
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CreatePath;
