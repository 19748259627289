import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table, Card, CardBody,
} from "reactstrap";

import { Link } from "react-router-dom";

//import Charts
import ChartSection from './ChartSection';

//import action
import { getChartsData as onGetChartsData } from "../../store/actions";
import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import CreateTask from './CreateTask';
import LatestTasks from "./LatestTasks";
import Tasks from "./Tasks"


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

const chartsData2 = [
        {
            id: 1,
            title: "Active Tasks",
            price: " 32",
            perstangeValue: " 100",
            bagdeColor: "success",
            istrendingArrow: false,
            seriesData: [{
                name: "Active Tasks",
                data: [1, 2, 5, 8, 9, 10, 14, 32],
            }],
            color: '["--bs-success", "--bs-transparent"]'
        },
        {
            id: 2,
            title: "Task Engagement",
            price: " 130",
            perstangeValue: " 10",
            bagdeColor: "success",
            istrendingArrow: false,
            seriesData: [{
                name: "Task Engagement",
                data: [0, 10, 100, 145, 200, 130, 120, 130],
            }],
            color: '["--bs-success", "--bs-transparent"]'
        },
        {
            id: 3,
            title: "Task Errors",
            price: " 14",
            perstangeValue: " 120",
            bagdeColor: "danger",
            istrendingArrow: false,
            seriesData: [{
                name: "Task Errors",
                data: [10, 1, 5, 1, 1, 12, 1, 14],
            }],
            color: '["--bs-danger", "--bs-transparent"]'
        },
        {
            id: 4,
            title: "Unassigned Tasks",
            price: " 10",
            perstangeValue: " 60",
            bagdeColor: "danger",
            istrendingArrow: false,
            seriesData: [{
                name: "Unassigned Tasks",
                data: [1, 1, 0, 7, 1, 0, 4, 10],
            }],
            color: '["--bs-danger", "--bs-transparent"]'
        },
    ];

const Dashboard = props => {
  const [modal, setmodal] = useState(false);
  const [subscribemodal, setSubscribemodal] = useState(false);
  const [periodData, setPeriodData] = useState([]);

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }));

  useEffect(() => {
    setTimeout(() => {
      //setSubscribemodal(true);
    }, 2000);
  }, []);

  useEffect(() => {
    setPeriodData(chartsData);
  }, [chartsData]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onGetChartsData("yearly"));
  }, [dispatch]);

  //meta title
  document.title = "Task Dashboard | XPS";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Task Dashboard")}
          />
          <ChartSection chartsData={chartsData2} />
          <div className="card">
            <div className="card-body">
              <h6 className="card-title">Task Manager</h6>
            </div>
          </div>
          <Row>
            <Col xl="3">
              <WelcomeComp />
              <Tasks />
            </Col>
            <Col xl="9">
              <LatestTasks />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <CreateTask />
            </Col>
          </Row>
        </Container>
      </div>

      {/* subscribe ModalHeader */}
      <Modal
        isOpen={subscribemodal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setSubscribemodal(!subscribemodal);
        }}
      >
        <div>
          <ModalHeader
            className="border-bottom-0"
            toggle={() => {
              setSubscribemodal(!subscribemodal);
            }}
          ></ModalHeader>
        </div>
        <div className="modal-body">
          <div className="text-center mb-4">
            <div className="avatar-md mx-auto mb-4">
              <div className="avatar-title bg-light  rounded-circle text-primary h1">
                <i className="mdi mdi-email-open"></i>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-10">
                <h4 className="text-primary">Subscribe !</h4>
                <p className="text-muted font-size-14 mb-4">
                  Subscribe our newletter and get notification to stay update.
                </p>

                <div
                  className="input-group rounded bg-light"
                >
                  <Input
                    type="email"
                    className="form-control bg-transparent border-0"
                    placeholder="Enter Email address"
                  />
                  <Button color="primary" type="button" id="button-addon2">
                    <i className="bx bxs-paper-plane"></i>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal);
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setmodal(!modal);
            }}
          >
            Order Details
          </ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Product id: <span className="text-primary">#SK2540</span>
            </p>
            <p className="mb-4">
              Billing Name: <span className="text-primary">Neal Matthews</span>
            </p>

            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage1} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Wireless Headphone (Black)
                        </h5>
                        <p className="text-muted mb-0">$ 225 x 1</p>
                      </div>
                    </td>
                    <td>$ 255</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage2} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Hoodie (Blue)
                        </h5>
                        <p className="text-muted mb-0">$ 145 x 1</p>
                      </div>
                    </td>
                    <td>$ 145</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Sub Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Shipping:</h6>
                    </td>
                    <td>Free</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodal(!modal);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);