import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
} from "reactstrap";

// XPS API Imports
import { _xps } from "@g7dao/public-project-webhooks";

import ProfileModal from "./ProfileModal";

import {
  Uuid,
  LevelName,
  Rank,
  Xp,
  Status,
} from "./LatestTranactionCol";

import TableContainer from "../../components/Common/TableContainer";
import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

const Level = props => {
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [getLevelsResponse, setGetLevelsResponse] = useState([]);

  useEffect(() => {
    getLevels()
  }, []);

  const toggleDetailsModal = (cellProps) => {
    if(cellProps.row !== undefined){
      setModalData(cellProps.row.original)
    }
    setModal(!modal)
  };

  const columns = useMemo(
    () => [
      {
        Header: "Uuid",
        accessor: "id",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <Uuid {...cellProps} />;
        },
      },
      {
        Header: "Level Name",
        accessor: "name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <LevelName {...cellProps} />;
        },
      },
      {
        Header: "Rank",
        accessor: "rankEarned",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Rank {...cellProps} />;
        },
      },
      {
        Header: "XP",
        accessor: "xpEarned",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Xp {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },
      {
        Header: "View Details",
        disableFilters: true,
        accessor: "view",
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => toggleDetailsModal(cellProps)}
            >
              View Details
            </Button>
          );
        },
      },
    ],
    []
  );

  const getLevels = () => {
    const resp = _xps.getAllLevels();
    resp
      .then((r) => {
        if (r.status === 201 || r.status === 200) {
          return r.json();
        }
      })
      .then((r) => {setGetLevelsResponse(r.data)})
      .catch(() => {
        alert("get tasks error");
      });
  };

  return (
    <React.Fragment>
      <ProfileModal modalData={modalData} isOpen={modal} toggle={toggleDetailsModal} />
      <Card>
        <CardBody>
          <div className="mb-4 h4 card-title">Level List</div>
          <TableContainer
            columns={columns}
            data={getLevelsResponse}
            isGlobalFilter={false}
            isAddOptions={false}
            customPageSize={8}
            customPageSizeOptions={2}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

Level.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withRouter(Level);
