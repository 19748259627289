import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboards
import DashboardAchievement from "../pages/DashboardAchievement";
import DashboardAttribute from "../pages/DashboardAttribute";
import DashboardDiscord from "../pages/DashboardDiscord";
import DashboardMain from "../pages/DashboardMain/index"
import DashboardLevel from "../pages/DashboardLevel/index"
import DashboardPath from "../pages/DashboardPath/index"
import DashboardQuest from "../pages/DashboardQuest/index"
import DashboardTask from "../pages/DashboardTask/index"
import DashboardWaitlist from "../pages/DashboardWaitlist";

const authProtectedRoutes = [
  { path: "/dashboard", component: DashboardMain },
  { path: "/dashboard-achievement", component: DashboardAchievement },
  { path: "/dashboard-attribute", component: DashboardAttribute },
  { path: "/dashboard-discord", component: DashboardDiscord },
  { path: "/dashboard-level", component: DashboardLevel },
  { path: "/dashboard-path", component: DashboardPath },
  { path: "/dashboard-quest", component: DashboardQuest },
  { path: "/dashboard-task", component: DashboardTask },
  { path: "/dashboard-waitlist", component: DashboardWaitlist },


  // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { authProtectedRoutes, publicRoutes }
