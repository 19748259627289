import React, { useEffect, useState, useRef, useMemo } from "react";
import { withRouter, Link } from "react-router-dom";
import TableContainer from "../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
} from "reactstrap";
import * as Yup from "yup";
import { Formik, Form, Field, useFormik } from "formik";
import { Name, Numeric, Email, Tags, Projects, Img } from "./contactlistCol";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

//redux
import { useSelector, useDispatch } from "react-redux";
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../assets/images/users/avatar-5.jpg";
import avatar7 from "../../assets/images/users/avatar-7.jpg";

// XPS API Imports
import { _xps, _file } from "@g7dao/public-project-webhooks";

const Index = props => {

  //meta title
  document.title="XPS | Achievements";

  const [achievement, setAchievement] = useState({
    name: '',
    description: '',
    rank: 0.01,
    xp: 0,
    rarity: '',
    publish: 0,
    status: 0,
  });
  const [achievementList, setAchievementList] = useState([]);
  const [assetObject, setAssetObject] = useState({});
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "Image",
        // accessor: "name",
        disableFilters: true, 
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.img ? (
              <div className="avatar-xs">
                <span className="avatar-title rounded-circle">
                  {cellProps.name.charAt(0)}
                </span>
              </div>
            ) : (
              <div>
                <img
                  className="rounded-circle avatar-xs"
                  src={cellProps.img}
                  alt=""
                />
              </div>
            )}
        </>
        ),
      },
      {
        Header: "Name",
        accessor: "name",
        disableFilters: true,
        isSorted: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Description",
        accessor: "description",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Rarity",
        accessor: "rarity",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Min Xp",
        accessor: "xp",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <Numeric {...cellProps} />{" "}
            </>
          );
        },
      },
      {
        Header: "Min Rank",
        accessor: "rank",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <Numeric {...cellProps} />{" "}
            </>
          );
        },
      },
      {
        Header: "Published",
        accessor: "publish",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Numeric {...cellProps} />;
        },
      },
      {
        Header: "Active",
        accessor: "status",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Numeric {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleAchievementEdit(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleAchievementDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    getAchievements();
    setIsEdit(false);
  }, []);

  const toggle = () => {
    setModal(!modal);
  };

  const getAchievements = () => {
    const resp = _xps.getAchievements();
    resp
      .then((r) => {
        if (r.status === 201 || r.status === 200) {
          return r.json();
        }
      })
      .then((r) => {
        setAchievementList(r.data)
        //console.log(r.data)
      })
      .catch(() => {
        alert("get tasks error");
      });
  };

  const processFormResults = arg => {
    const _asset = assetObject

    arg["rank"] = +arg["rank"];
    arg["xp"] = +arg["xp"];
    arg["publish"] = +arg["publish"];
    arg["status"] = +arg["status"];

    arg["image"] = _asset["variants"][0];
    arg["asset"] = _asset;

    if(arg["id"]) {
      const uuid = arg["id"];
      delete arg["id"]; // we don't want the id in the body
      // update achievement
      const resp = _xps.updateAchievement(arg, uuid);
      resp
        .then((r) => {
          if (r.status === 201 || r.status === 200) {
            return r.json();
          }

          throw new Error(`There was an API error: ${r.status}`);
        })
        .then((r) => {
          console.log(r.data)
          getAchievements();
          alert("Form submission success");
          toggle();
        })
        .catch((e) => {
          alert("Form submission error");
        });
    } else {
      // create new achievement
      const resp = _xps.createAchievement(arg);
      resp
        .then((r) => {
          if (r.status === 201 || r.status === 200) {
            return r.json();
          }

          throw new Error(`There was an API error: ${r.status}`);
        })
        .then((r) => {
          setAchievementList(_achievementList => [..._achievementList, r.data]);
          alert("Form submission success");
          toggle();
        })
        .catch((e) => {
          alert("Form submission error");
        });
    }
  };

  const handleAssetsUpload = e => {
    /*
     <html>
       <body>
         <form
           action="INSERT_UPLOAD_URL_HERE"
           method="post"
           enctype="multipart/form-data"
         >
           <input type="file" id="myFile" name="file" />
           <input type="submit" />
         </form>
       </body>
      </html>
    */

    let fileObj = e.target.files[0];

    const _sign = _file.cfImageSign()
    _sign
      .then((response) => response.json())
      .then((r) => {
        const _d = r.data;
        if(_d["success"]) {
          // CF upload URL and image upload ID. Expires in 30 min
          const _r = _d["result"];

          // upload actual image file
          const upload = cfUpload(_r["uploadURL"], fileObj)
          upload.then((r) => {
            console.log(r)
            setAssetObject(r)
            alert("image upload success");
          }).catch((e) => {
            throw new Error(`There was an API error: ${e.message}`);
          })
        } else {
          throw new Error(`There was an API error: ${r.status}`);
        }
      })
      .catch((e) => {
        alert("image sign error");
      });
  }

  const cfUpload = async (uploadUrl, fileObj) => {
    const formData = new FormData()
    formData.append('file', fileObj, fileObj.name)

    const uploadResult = await fetch(uploadUrl, {
      method: 'post',
      body: formData
    })
    const result = await uploadResult.json()
    return result.result || undefined
  }

  const handleAchievementEdit = arg => {
    const _a = arg;
    if(_a.publish === 1) {
      alert("This Achievement can't be updated. It has been published.")
      return
    }

    setAchievement({
      id: _a.id,
      name: _a.name,
      description: _a.description,
      rank: _a.rank,
      xp: _a.xp,
      rarity: _a.rarity,
      publish: _a.publish,
      status: _a.status,
    });

    setIsEdit(true);
    toggle();
  };

  const handleAchievementDelete = arg => {
    const _a = arg;
    if(_a.publish === 1) {
      alert("This Achievement can't be deleted. It has been published.")
      return
    }

    const resp = _xps.deleteAchievement(_a.id);
    resp
    .then((r) => {
      if (r.status === 201 || r.status === 200) {
        return r.json();
      }
      throw new Error(`There was an API error: ${r.status}`);
    })
    .then((r) => {
      getAchievements();
      alert("yeah! delete success")
    })
    .catch((e) => {
      alert("delete error");
    });
  }

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const handleUserClicks = () => {
    setAchievement({
      name: '',
      description: '',
      rank: 0.01,
      xp: 0,
      rarity: '',
      publish: 0,
      status: 0,
    });
    setIsEdit(false);
    toggle();
  };

  const ErrorSchema = Yup.object().shape({
   name: Yup.string()
     .min(2, 'Too Short!')
     .max(50, 'Too Long!')
     .required('Required'),
   description: Yup.string()
     .min(2, 'Too Short!')
     .max(250, 'Too Long!')
     .required('Required'),
   rank: Yup.string()
     .min(3, 'Too Short!')
     .max(5, 'Too Long!')
     .required('Required'),
   xp: Yup.string()
     .min(1, 'Too Short!')
     .max(5, 'Too Long!')
     .required('Required'),
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Contacts" breadcrumbItem="Achievement Dashboard" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    name="Achievement"
                    columns={columns}
                    data={achievementList}
                    isGlobalFilter={true}
                    isAddUserList={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Achievement" : "Create Achievement"}
                    </ModalHeader>
                    <ModalBody>
                    <Formik
                     initialValues={achievement}
                     validationSchema={ErrorSchema}
                     onSubmit={values => {
                       processFormResults(values);
                     }}
                    >
                     {({ errors, touched }) => (
                       <Form>
                          <Row form>
                           <Col xs={12}>
                             <div className="mb-3">
                               <Label className="form-label">Name</Label>
                               <Field name="name" placeholder="enter a unique name" className="form-control form-control" />
                                 {errors.name && touched.name ? (
                                  <div>
                                    {errors.name}
                                  </div>
                                 ) : null}
                             </div>
                           </Col>
                          </Row>
                          <Row form>
                           <Col xs={12}>
                             <div className="mb-3">
                               <Label className="form-label">Description</Label>
                               <Field name="description" placeholder="enter a short description" className="form-control form-control" />
                                 {errors.description && touched.description ? (
                                  <div>
                                    {errors.description}
                                  </div>
                                 ) : null}
                             </div>
                           </Col>
                          </Row>
                          <Row form>
                           <Col xs={6}>
                             <div className="mb-3">
                               <Label className="form-label">Rank</Label>
                               <Field name="rank" placeholder="enter earned Rank" className="form-control form-control" />
                                 {errors.rank && touched.rank ? (
                                  <div>
                                    {errors.rank}
                                  </div>
                                 ) : null}
                             </div>
                           </Col>
                           <Col xs={6}>
                             <div className="mb-3">
                               <Label className="form-label">XP</Label>
                               <Field name="xp" placeholder="enter earned XP" className="form-control form-control" />
                                 {errors.xp && touched.xp ? (
                                  <div>
                                    {errors.xp}
                                  </div>
                                 ) : null}
                             </div>
                           </Col>
                          </Row>
                         {!!isEdit
                           ? <Row form>
                             <Col xs={6}>
                               <div>
                                  <Label className="form-label">Publish</Label>
                                  <Field
                                    as="select"
                                    name="publish"
                                    className="form-select"
                                  >
                                    <option>Is your Achievement Published?</option>
                                    <option value={1}>TRUE</option>
                                    <option value={0}>FALSE</option>
                                  </Field>
                                </div>
                             </Col>
                             <Col xs={6}>
                               <div className="mb-3">
                                  <Label className="form-label">Status</Label>
                                  <Field
                                    as="select"
                                    name="status"
                                    className="form-select"
                                  >
                                    <option>What is your Achievement Status?</option>
                                    <option value={1}>ACTIVE</option>
                                    <option value={0}>NOT ACTIVE</option>
                                  </Field>
                                </div>
                             </Col>
                            </Row>
                           : ''
                         }
                          <Row form>
                            <Col xs={12}>
                              <div className="mb-3">
                                <Label className="form-label">Rarity</Label>
                                <Field
                                  as="select"
                                  name="rarity"
                                  className="form-select"
                                >
                                  <option>How rare is this Achievement?</option>
                                  <option value={"common"}>Common</option>
                                  <option value={"uncommon"}>Uncommon</option>
                                  <option value={"rare"}>Rare</option>
                                  <option value={"epic"}>Epic</option>
                                  <option value={"legendary"}>Legendary</option>
                                  <option value={"ancient"}>Ancient</option>
                                  <option value={"overachiever"}>Overachiever</option>
                                </Field>
                              </div>
                              <div className="mb-3">
                                <Label htmlFor="formFile" className="form-label">Image</Label>
                                <Input
                                  name="image"
                                  className="form-control"
                                  type="file"
                                  id="formFile"
                                  onChange={(e) => handleAssetsUpload(e)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="text-end">
                                <button
                                  type="submit"
                                  className="btn btn-success save-user"
                                >
                                  {!!isEdit ? "Update Achievement" : "Create Achievement"}
                                </button>
                              </div>
                            </Col>
                          </Row>
                       </Form>
                     )}
                    </Formik>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Index);
