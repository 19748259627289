import React, { useEffect, useState, useRef, useMemo } from "react";
import { withRouter, Link } from "react-router-dom";
import TableContainer from "../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
} from "reactstrap";
import * as Yup from "yup";
import { Formik, Form, Field, useFormik } from "formik";
import { Name, Numeric, Email, Tags, Projects, Img } from "./contactlistCol";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

// XPS API Imports
import { _xps } from "@g7dao/public-project-webhooks";

const Index = props => {

  //meta title
  document.title="XPS | Attributes";

  const [attribute, setAttribute] = useState({
    attribute: '',
    name: '',
    description: '',
    level: 0
  });
  const [attributeList, setAttributeList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "Attribute",
        accessor: "attribute",
        disableFilters: true,
        isSorted: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Name",
        accessor: "name",
        disableFilters: true,
        isSorted: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Description",
        accessor: "description",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Level",
        accessor: "level",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <Numeric {...cellProps} />{" "}
            </>
          );
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleAttributeEdit(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleAttributeDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    getAttributes();
    setIsEdit(false);
  }, []);

  const toggle = () => {
    setModal(!modal);
  };

  const getAttributes = () => {
    const resp = _xps.getAttributes();
    resp
      .then((r) => {
        if (r.status === 201 || r.status === 200) {
          return r.json();
        }
      })
      .then((r) => {
        setAttributeList(r.data)
        //console.log(r.data)
      })
      .catch(() => {
        alert("get attributes error");
      });
  };

  const processFormResults = arg => {
    arg["rank"] = +arg["rank"];

    if(arg["id"]) {
      const uuid = arg["id"];
      delete arg["id"]; // we don't want the id in the body
      // update attribute
      const resp = _xps.updateAttribute(arg, uuid);
      resp
        .then((r) => {
          if (r.status === 201 || r.status === 200) {
            return r.json();
          }

          throw new Error(`There was an API error: ${r.status}`);
        })
        .then((r) => {
          console.log(r.data)
          getAttributes();
          alert("Form submission success");
          toggle();
        })
        .catch((e) => {
          alert("Form submission error");
        });
    } else {
      // create new attribute
      const resp = _xps.createAttribute(arg);
      resp
        .then((r) => {
          if (r.status === 201 || r.status === 200) {
            return r.json();
          }

          throw new Error(`There was an API error: ${r.status}`);
        })
        .then((r) => {
          setAttributeList(_attributeList => [..._attributeList, r.data]);
          alert("Form submission success");
          toggle();
        })
        .catch((e) => {
          alert("Form submission error");
        });
    }
  };

  const handleAttributeEdit = arg => {
    const _a = arg;
    if(_a.publish === 1) {
      alert("This Attribute can't be updated. It has been published.")
      return
    }

    setAttribute({
      id: _a.id,
      attribute: _a.attribute,
      name: _a.name,
      description: _a.description,
      level: _a.level
    });

    setIsEdit(true);
    toggle();
  };

  const handleAttributeDelete = arg => {
    const _a = arg;
    if(_a.publish === 1) {
      alert("This Attribute can't be deleted. It has been published.")
      return
    }

    const resp = _xps.deleteAttribute(_a.id);
    resp
    .then((r) => {
      if (r.status === 201 || r.status === 200) {
        return r.json();
      }
      throw new Error(`There was an API error: ${r.status}`);
    })
    .then((r) => {
      getAttributes();
      alert("yeah! delete success")
    })
    .catch((e) => {
      alert("delete error");
    });
  }

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const handleUserClicks = () => {
    setAttribute({
      attribute: '',
      name: '',
      description: '',
      rank: 0,
    });
    setIsEdit(false);
    toggle();
  };

  const ErrorSchema = Yup.object().shape({
   attribute: Yup.string()
     .min(2, 'Too Short!')
     .max(50, 'Too Long!')
     .required('Required'),
   name: Yup.string()
     .min(2, 'Too Short!')
     .max(50, 'Too Long!')
     .required('Required'),
   description: Yup.string()
     .min(2, 'Too Short!')
     .max(250, 'Too Long!')
     .required('Required'),
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Contacts" breadcrumbItem="Attribute Dashboard" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    name="Attribute"
                    columns={columns}
                    data={attributeList}
                    isGlobalFilter={true}
                    isAddUserList={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Attribute" : "Create Attribute"}
                    </ModalHeader>
                    <ModalBody>
                    <Formik
                     initialValues={attribute}
                     validationSchema={ErrorSchema}
                     onSubmit={values => {
                       processFormResults(values);
                     }}
                    >
                     {({ errors, touched }) => (
                       <Form>
                          <Row form>
                           <Col xs={12}>
                             <div className="mb-3">
                                <Label className="form-label">Attribute</Label>
                                <Field
                                  as="select"
                                  name="attribute"
                                  className="form-select"
                                >
                                  <option>Select an Attribute Type</option>
                                  <option value="rank">Rank</option>
                                  <option value="category">Category</option>
                                  <option value="subCategory">Subcategory</option>
                                </Field>
                              </div>
                           </Col>
                          </Row>
                          <Row form>
                           <Col xs={12}>
                             <div className="mb-3">
                               <Label className="form-label">Name</Label>
                               <Field name="name" placeholder="enter a unique name" className="form-control form-control" />
                                 {errors.name && touched.name ? (
                                  <div>
                                    {errors.name}
                                  </div>
                                 ) : null}
                             </div>
                           </Col>
                          </Row>
                          <Row form>
                           <Col xs={12}>
                             <div className="mb-3">
                               <Label className="form-label">Description</Label>
                               <Field name="description" placeholder="enter a short description" className="form-control form-control" />
                                 {errors.description && touched.description ? (
                                  <div>
                                    {errors.description}
                                  </div>
                                 ) : null}
                             </div>
                           </Col>
                          </Row>
                          <Row form>
                           <Col xs={6}>
                             <div className="mb-3">
                               <Label className="form-label">Level</Label>
                               <Field name="level" placeholder="enter Level" className="form-control form-control" />
                                 {errors.level && touched.level ? (
                                  <div>
                                    {errors.level}
                                  </div>
                                 ) : null}
                             </div>
                           </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="text-end">
                                <button
                                  type="submit"
                                  className="btn btn-success save-user"
                                >
                                  {!!isEdit ? "Update Attribute" : "Create Attribute"}
                                </button>
                              </div>
                            </Col>
                          </Row>
                       </Form>
                     )}
                    </Formik>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Index);
