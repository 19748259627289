import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import {
  Button,
  Card,
  CardBody, Col, Row,
} from "reactstrap";

// XPS API Imports
import { _xps } from "@g7dao/public-project-webhooks";

import ProfileModal from "./ProfileModal";

import {
  Uuid,
  PathName,
  QuestName,
  Rank,
  Xp,
  Status,
} from "./LatestTranactionCol";

import TableContainer from "../../components/Common/TableContainer";

const Quest = props => {
  const [modal1, setModal1] = useState(false);
  const [modalData, setModalData] = useState({});
  const [getQuestsResponse, setGetQuestsResponse] = useState([]);

  useEffect(() => {
    getQuests()
  }, []);
  
  const toggleViewModal = (cellProps) => {
    if(cellProps.row !== undefined){
      setModalData(cellProps.row.original)
    }
    setModal1(!modal1)
  };

  const columns = useMemo(
    () => [
      {
        Header: "Uuid",
        accessor: "id",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <Uuid {...cellProps} />;
        },
      },
      {
        Header: "Quest Name",
        accessor: "name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <QuestName {...cellProps} />;
        },
      },
      {
        Header: "Path",
        accessor: "path",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <PathName {...cellProps} />;
        },
      },
      {
        Header: "Rank",
        accessor: "rankEarned",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Rank {...cellProps} />;
        },
      },
      {
        Header: "XP",
        accessor: "xpEarned",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Xp {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },
      {
        Header: "View Details",
        disableFilters: true,
        accessor: "view",
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => toggleViewModal(cellProps)}
            >
              View Details
            </Button>
          );
        },
      },
    ],
    []
  );

  const getQuests = () => {
    const resp = _xps.getAllQuests();
    resp
      .then((r) => {
        if (r.status === 201 || r.status === 200) {
          return r.json();
        }
      })
      .then((r) => {setGetQuestsResponse(r.data)})
      .catch(() => {
        alert("get tasks error");
      });
  };

  return (
    <React.Fragment>
      <ProfileModal modalData={modalData} isOpen={modal1} toggle={toggleViewModal} />
      <Card>
        <CardBody>
          <div className="mb-4 h4 card-title">Quest List</div>
          <TableContainer
            columns={columns}
            data={getQuestsResponse}
            isGlobalFilter={false}
            isAddOptions={false}
            customPageSize={8}
            customPageSizeOptions={2}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

Quest.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withRouter(Quest);
