import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Input,
    Modal,
    ModalHeader,
} from "reactstrap";

import { Link } from "react-router-dom";

//import Charts
import ChartSection from './ChartSection';

//import action
import { getChartsData as onGetChartsData } from "../../store/actions";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import CreateLevel from './CreateLevel';
import LatestLevel from "./LatestLevel";
import Tasks from "./Tasks"


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

const chartsData2 = [
        {
            id: 1,
            title: "Active Levels",
            price: " 32",
            perstangeValue: " 100",
            bagdeColor: "success",
            istrendingArrow: false,
            seriesData: [{
                name: "Active Levels",
                data: [1, 2, 5, 8, 9, 10, 14, 32],
            }],
            color: '["--bs-success", "--bs-transparent"]'
        },
        {
            id: 2,
            title: "Level Engagement",
            price: " 130",
            perstangeValue: " 10",
            bagdeColor: "success",
            istrendingArrow: false,
            seriesData: [{
                name: "Level Engagement",
                data: [0, 10, 100, 145, 200, 130, 120, 130],
            }],
            color: '["--bs-success", "--bs-transparent"]'
        },
        {
            id: 3,
            title: "Level Errors",
            price: " 14",
            perstangeValue: " 120",
            bagdeColor: "danger",
            istrendingArrow: false,
            seriesData: [{
                name: "Level Errors",
                data: [10, 1, 5, 1, 1, 12, 1, 14],
            }],
            color: '["--bs-danger", "--bs-transparent"]'
        },
        {
            id: 4,
            title: "Unassigned Levels",
            price: " 10",
            perstangeValue: " 60",
            bagdeColor: "danger",
            istrendingArrow: false,
            seriesData: [{
                name: "Unassigned Levels",
                data: [1, 1, 0, 7, 1, 0, 4, 10],
            }],
            color: '["--bs-danger", "--bs-transparent"]'
        },
    ];

const Dashboard = props => {
  const [subscribemodal, setSubscribemodal] = useState(false);
  const [periodData, setPeriodData] = useState([]);

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }));

  useEffect(() => {
    setTimeout(() => {
      //setSubscribemodal(true);
    }, 2000);
  }, []);

  useEffect(() => {
    setPeriodData(chartsData);
  }, [chartsData]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onGetChartsData("yearly"));
  }, [dispatch]);

  //meta title
  document.title = "Dashboard | G7P";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Level Dashboard")}
          />
          <ChartSection chartsData={chartsData2} />
          <div class="card">
            <div class="card-body">
              <h6 class="card-title">Level Manager</h6>
            </div>
          </div>
          <Row>
            <Col xl="3">
              <WelcomeComp />
              <Tasks />
            </Col>
            <Col xl="9">
              <LatestLevel />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <CreateLevel />
            </Col>
          </Row>
        </Container>
      </div>

      {/* subscribe ModalHeader */}
      <Modal
        isOpen={subscribemodal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setSubscribemodal(!subscribemodal);
        }}
      >
        <div>
          <ModalHeader
            className="border-bottom-0"
            toggle={() => {
              setSubscribemodal(!subscribemodal);
            }}
          ></ModalHeader>
        </div>
        <div className="modal-body">
          <div className="text-center mb-4">
            <div className="avatar-md mx-auto mb-4">
              <div className="avatar-title bg-light  rounded-circle text-primary h1">
                <i className="mdi mdi-email-open"></i>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-10">
                <h4 className="text-primary">Subscribe !</h4>
                <p className="text-muted font-size-14 mb-4">
                  Subscribe our newletter and get notification to stay update.
                </p>

                <div
                  className="input-group rounded bg-light"
                >
                  <Input
                    type="email"
                    className="form-control bg-transparent border-0"
                    placeholder="Enter Email address"
                  />
                  <Button color="primary" type="button" id="button-addon2">
                    <i className="bx bxs-paper-plane"></i>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);