import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  Row,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table, UncontrolledTooltip,
} from "reactstrap"

import {Link} from "react-router-dom";

// XPS API Imports
import { _xps } from "@g7dao/public-project-webhooks";

const ProfileModal = props => {
    const [getPath, setPath] = useState({
    name: "",
    description: "",
    instructions: "",
    level: "",
    ordered: 0,
    order: 0,
    required: 0,
    xpEarned: "",
    rankEarned: "",
    achievement: "",
    entryXp: "",
    entryRank: "",
    entryAchievement: "",
    exitXp: "",
    exitRank: "",
    exitAchievement: "",
    repeat: 0,
    repeatMax: 0,
    repeatXp: "",
    repeatRank: "",
    repeatDecay: "",
  });

  const { modalData, isOpen, toggle } = props
  useEffect(() => {
    setPath(modalData)
  });

  const deleteObject = () => {
    const resp = _xps.deletePath(modalData.id);
    resp
      .then((r) => {
        if (r.status === 201 || r.status === 200) {
          return r.json();
        }
      })
      .then((r) => alert("yeah! delete success"))
      .catch((e) => {
        alert("delete error");
      });
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Path Details</ModalHeader>
        <ModalBody>
          <p className="mb-2">
            Uuid: <span className="text-primary">{modalData.id}</span>
          </p>
          <p className="mb-3">
            <Label for="basicpill-firstname-input1">
              Path Name
            </Label>
            <Input
              type="text"
              className="form-control"
              id="basicpill-firstname-input1"
              placeholder="Enter a memorable Path Name"
              value={getPath.name || ""}
              onChange={(e) => {
                setPath((prevState) => {
                  return { ...prevState, name: e.target.value };
                });
              }}
            />
          </p>
          <p className="mb-3">
            <Label for="basicpill-lastname-input2">
              Description
            </Label>
            <Input
              type="text"
              className="form-control"
              id="basicpill-lastname-input2"
              value={getPath.description || ""}
              onChange={(e) => {
                setPath((prevState) => {
                  return { ...prevState, description: e.target.value };
                });
              }}
            />
          </p>
          <p className="mb-3">
            <Label for="basicpill-lastname-input2">
              Instructions
            </Label>
            <Input
              type="text"
              className="form-control"
              id="basicpill-lastname-input2"
              value={getPath.instructions || ""}
              onChange={(e) => {
                setPath((prevState) => {
                  return { ...prevState, instructions: e.target.value };
                });
              }}
            />
          </p>
          <div className="table-responsive">
            <Table className="table align-middle table-nowrap">
              <tbody>
                <tr>
                  <td>
                    <Label for="basicpill-servicetax-input8">
                    Ordered Paths Required?
                    </Label>
                    <select
                    className="form-select"
                    onChange={(e) => {
                      setPath((prevState) => {
                        return { ...prevState, ordered: e.target.value };
                      });
                    }}
                    >
                    <option defaultValue>
                      completed Path in order?
                    </option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                    </select>
                  </td>
                  <td>
                    <Label for="basicpill-cstno-input7">
                      Path Required?
                    </Label>
                    <select
                      className="form-select"
                      onChange={(e) => {
                        setPath((prevState) => {
                          return { ...prevState, required: e.target.value };
                        });
                      }}
                    >
                      <option defaultValue>
                        Path required?
                      </option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Label for="basicpill-phoneno-input3">
                      Min Rank
                        <Link to="#" className="m-1" id="m-r">
                          <i class="fas fa-comment-dots"></i>
                          <UncontrolledTooltip placement="top" target="m-r">
                            minimum rank required to start Path
                          </UncontrolledTooltip>
                        </Link>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-phoneno-input3"
                      placeholder="1.0.0"
                      value={getPath.entryRank || ""}
                      onChange={(e) => {
                        setPath((prevState) => {
                          return { ...prevState, entryRank: e.target.value };
                        });
                      }}
                    />
                  </td>
                  <td>
                    <Label for="basicpill-email-input4">
                      Min XP
                        <Link to="#" className="m-1" id="m-xp">
                          <i class="fas fa-comment-dots"></i>
                          <UncontrolledTooltip placement="top" target="m-xp">
                            minimum XP required to start Path
                          </UncontrolledTooltip>
                        </Link>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-email-input4"
                      placeholder="1000"
                      value={getPath.entryXp || ""}
                      onChange={(e) => {
                        setPath((prevState) => {
                          return { ...prevState, entryXp: e.target.value };
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Label for="basicpill-phoneno-input3">
                      Exit Rank
                        <Link to="#" className="m-1" id="exit-r">
                          <i class="fas fa-comment-dots"></i>
                          <UncontrolledTooltip placement="top" target="exit-r">
                            rank required to complete Path
                          </UncontrolledTooltip>
                        </Link>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-phoneno-input3"
                      placeholder="0.1.0"
                      value={getPath.exitRank || ""}
                      onChange={(e) => {
                        setPath((prevState) => {
                          return { ...prevState, exitRank: e.target.value };
                        });
                      }}
                    />
                  </td>
                  <td>
                    <Label for="basicpill-email-input4">
                      Exit XP
                        <Link to="#" className="m-1" id="exit-xp">
                          <i class="fas fa-comment-dots"></i>
                          <UncontrolledTooltip placement="top" target="exit-xp">
                            XP required to complete Path
                          </UncontrolledTooltip>
                        </Link>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-email-input4"
                      placeholder="100"
                      value={getPath.exitXp || ""}
                      onChange={(e) => {
                        setPath((prevState) => {
                          return { ...prevState, exitXp: e.target.value };
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Label for="basicpill-phoneno-input3">
                      Earned Rank
                        <Link to="#" className="m-1" id="e-r">
                          <i class="fas fa-comment-dots"></i>
                          <UncontrolledTooltip placement="top" target="e-r">
                            rank earned by completing Path
                          </UncontrolledTooltip>
                        </Link>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-phoneno-input3"
                      placeholder="0.1.0"
                      value={getPath.rankEarned || ""}
                      onChange={(e) => {
                        setPath((prevState) => {
                          return { ...prevState, rankEarned: e.target.value };
                        });
                      }}
                    />
                  </td>
                  <td>
                    <Label for="basicpill-email-input4">
                      Earned XP
                        <Link to="#" className="m-1" id="e-xp">
                          <i class="fas fa-comment-dots"></i>
                          <UncontrolledTooltip placement="top" target="e-xp">
                            XP earned by completing Path
                          </UncontrolledTooltip>
                        </Link>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-email-input4"
                      placeholder="100"
                      value={getPath.xpEarned || ""}
                      onChange={(e) => {
                        setPath((prevState) => {
                          return { ...prevState, xpEarned: e.target.value };
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Label for="basicpill-servicetax-input8">
                      Repeat?
                    </Label>
                    <select
                      className="form-select"
                      onChange={(e) => {
                        setPath((prevState) => {
                          return { ...prevState, repeat: e.target.value };
                        });
                      }}
                    >
                      <option defaultValue>
                        Allow Path to be repeated?
                      </option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </td>
                  <td>
                    <Label>Repeat Decay</Label>
                    <select
                      className="form-select"
                      onChange={(e) => {
                        setPath((prevState) => {
                          return { ...prevState, repeatDecay: e.target.value };
                        });
                      }}
                    >
                      <option defaultValue>
                        Select Calculation Type
                      </option>
                      <option value="0">0%</option>
                      <option value="10">10%</option>
                      <option value="20">20%</option>
                      <option value="25">25%</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>

                  </td>
                  <td>

                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col xl="6">
              <Button
                type="button"
                color="danger"
                onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) deleteObject() } }
              >
                Delete
              </Button>
            </Col>
            <Col xl="6">
              <Button type="button" color="success">
                Update
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </div>
    </Modal>
  )
}

ProfileModal.propTypes = {
  modalData: PropTypes.object,
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default ProfileModal
