import React, { useEffect, useState, useRef, useMemo } from "react";
import { withRouter, Link } from "react-router-dom";
import TableContainer from "../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  UncontrolledTooltip,
} from "reactstrap";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { Name, Numeric, Email, TableLink, JoinedSince } from "./contactlistCol";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

// XPS API Imports
import { _xps } from "@g7dao/public-project-webhooks";

const Index = props => {

  //meta title
  document.title="XPS | Waitlists";

  const [waitlist, setWaitlist] = useState({
    id: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    discordHandle: "",
    position: "",
  });
  const [userData, setUserData] = useState({
    id: '',
  });
  const [approvalModal, setApprovalModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [waitlists, setWaitlistList] = useState([]);
  const [modal, setModal] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "Discord",
        accessor: "discordHandle",
        disableFilters: true,
        isSorted: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Email",
        accessor: "emailAddress",
        disableFilters: true,
        isSorted: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Invite Code",
        accessor: "inviteCode",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <TableLink {...cellProps} />;
        },
      },
      {
        Header: "Referral Code",
        accessor: "referralCode",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Referrals",
        accessor: "referrals",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <Numeric {...cellProps} />{" "}
            </>
          );
        },
      },
      {
        Header: "Position",
        accessor: "position",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <Numeric {...cellProps} />{" "}
            </>
          );
        },
      },
      {
        Header: "Approved",
        accessor: "approved",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <Numeric {...cellProps} />{" "}
            </>
          );
        },
      },
      {
        Header: "Days",
        accessor: "ts",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <JoinedSince {...cellProps} />{" "}
            </>
          );
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleApprovalModal(userData);
                  setApprovalModal(!approvalModal)
                }}
              >
                <i className="mdi mdi-check font-size-18" id="approvaltooltip" />
                <UncontrolledTooltip placement="top" target="approvaltooltip">
                  Approve
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-warning"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleWaitlistEdit(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleDeleteModal(userData);
                  setDeleteModal(!deleteModal)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    getWaitlists();
  }, []);

  const getWaitlists = () => {
    const resp = _xps.getWaitlistSearch();
    resp
      .then((r) => {
        if (r.status === 201 || r.status === 200) {
          return r.json();
        }
      })
      .then((r) => {
        setWaitlistList(r.data)
      })
      .catch(() => {
        alert("get waitlist error");
      });
  };

  const processFormResults = arg => {
    arg["position"] = +arg["position"];

    if(arg["id"]) {
      const uuid = arg.id;

      delete arg["id"]; // we don't want the id in the body
      // update waitlist
      const resp = _xps.updateWaitlist(arg, uuid);
      resp
        .then((r) => {
          if (r.status === 201 || r.status === 200) {
            return r.json();
          }

          throw new Error(`There was an API error: ${r.status}`);
        })
        .then((r) => {
          getWaitlists();
          setModal(!modal);
          alert("Form submission success");
        })
        .catch((e) => {
          alert("Form submission error");
        });
    } else {
      alert("Form submission error");
    }
  };

  const handleWaitlistEdit = arg => {
    const _a = arg;
    setWaitlist({
      id: _a.id,
      firstName: _a.firstName,
      lastName: _a.lastName,
      emailAddress: _a.emailAddress,
      discordHandle: _a.discordHandle,
      position: _a.position
    });
    setModal(!modal);
  };

  const submitApproval = () => {
    setApprovalModal(!approvalModal)

    const resp = _xps.createWaitListApproval({},userData.id);
    resp
    .then((r) => {
      if (r.status === 201 || r.status === 200) {
        return r.json();
      }
      throw new Error(`There was an API error: ${r.status}`);
    })
    .then((r) => {
      getWaitlists();
      alert("yeah! approval success")
    })
    .catch((e) => {
      alert("approval error");
    });
  };

  const handleApprovalModal = arg => {
    setUserData({
      id: arg.id,
    });
  };

  const handleDeleteModal = arg => {
    setUserData({
      id: arg.id,
    });
  }

  const submitDelete = () => {
    setDeleteModal(!deleteModal)

    const resp = _xps.deleteWaitlistEntry(userData.id);
    resp
    .then((r) => {
      if (r.status === 201 || r.status === 200) {
        return r.json();
      }
      throw new Error(`There was an API error: ${r.status}`);
    })
    .then((r) => {
      getWaitlists();
      alert("yeah! delete success")
    })
    .catch((e) => {
      alert("delete error");
    });
  }

  const handleUserClicks = () => {
    alert('Waitlist entry must be completed from waitlist.game7.io');
    // setWaitlist({
    //   id: "",
    //   firstName: "",
    //   lastName: "",
    //   emailAddress: "",
    //   discordHandle: "",
    //   position: "",
    // });
    // setModal(!modal);
  };

  const ErrorSchema = Yup.object().shape({
   position: Yup.string()
     .min(1, 'Too Short!')
     .max(5, 'Too Long!')
     .required('Required'),
   firstName: Yup.string()
     .min(2, 'Too Short!')
     .max(50, 'Too Long!')
     .required('Required'),
   lastName: Yup.string()
     .min(2, 'Too Short!')
     .max(50, 'Too Long!')
     .required('Required'),
   emailAddress: Yup.string()
     .min(2, 'Too Short!')
     .max(250, 'Too Long!')
     .required('Required'),
   discordHandle: Yup.string()
     .min(2, 'Too Short!')
     .max(25, 'Too Long!')
     .required('Required'),
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Contacts" breadcrumbItem="Waitlist Dashboard" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    name="Waitlist"
                    columns={columns}
                    data={waitlists}
                    isGlobalFilter={true}
                    isAddUserList={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />
                  <Modal
                    isOpen={modal}
                    toggle={() => {
                      setModal(!modal);
                    }}
                  >
                    <ModalHeader
                      toggle={() => {
                        setModal(!modal);
                      }}
                      tag="h4"
                    >
                      Edit Waitlist
                    </ModalHeader>
                    <ModalBody>
                    <Formik
                     initialValues={waitlist}
                     validationSchema={ErrorSchema}
                     onSubmit={values => {
                       processFormResults(values);
                     }}
                    >
                     {({ errors, touched }) => (
                       <Form>
                          <Row form>
                           <Col xs={12}>
                             <div className="mb-3">
                               <Label className="form-label">First Name</Label>
                               <Field name="firstName" placeholder="enter a First Name" className="form-control form-control" />
                                 {errors.firstName && touched.firstName ? (
                                  <div>
                                    {errors.firstName}
                                  </div>
                                 ) : null}
                             </div>
                           </Col>
                          </Row>
                          <Row form>
                           <Col xs={12}>
                             <div className="mb-3">
                               <Label className="form-label">Last Name</Label>
                               <Field name="lastName" placeholder="enter a Last Name" className="form-control form-control" />
                                 {errors.lastName && touched.lastName ? (
                                  <div>
                                    {errors.lastName}
                                  </div>
                                 ) : null}
                             </div>
                           </Col>
                          </Row>
                          <Row form>
                           <Col xs={12}>
                             <div className="mb-3">
                               <Label className="form-label">Email Address</Label>
                               <Field name="emailAddress" placeholder="enter an Email Address" className="form-control form-control" />
                                 {errors.emailAddress && touched.emailAddress ? (
                                  <div>
                                    {errors.emailAddress}
                                  </div>
                                 ) : null}
                             </div>
                           </Col>
                          </Row>
                          <Row form>
                           <Col xs={12}>
                             <div className="mb-3">
                               <Label className="form-label">Discord Handle</Label>
                               <Field name="discordHandle" placeholder="enter a Discord Handle" className="form-control form-control" />
                                 {errors.discordHandle && touched.discordHandle ? (
                                  <div>
                                    {errors.discordHandle}
                                  </div>
                                 ) : null}
                             </div>
                           </Col>
                          </Row>
                          <Row form>
                           <Col xs={6}>
                             <div className="mb-3">
                               <Label className="form-label">Position</Label>
                               <Field name="position" placeholder="update position" className="form-control form-control" />
                                 {errors.position && touched.position ? (
                                  <div>
                                    {errors.position}
                                  </div>
                                 ) : null}
                             </div>
                           </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="text-end">
                                <button
                                  type="submit"
                                  className="btn btn-success save-user"
                                >
                                  Update Waitlist
                                </button>
                              </div>
                            </Col>
                          </Row>
                       </Form>
                     )}
                    </Formik>
                    </ModalBody>
                  </Modal>

                  <Modal
                    isOpen={approvalModal}
                    toggle={() => {
                      setApprovalModal(!approvalModal);
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                  >
                    <div className="modal-header">
                      <h5 className="modal-title" id="staticBackdropLabel">User Approval</h5>
                      <button type="button" className="btn-close"
                        onClick={() => {
                          setApprovalModal(false);
                        }} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                      <p>Are you sure you want to APPROVE this user?</p>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => {
                        setApprovalModal(false);
                        }}
                      >
                        On 2nd thought, not yet.
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                          submitApproval()
                        }}
                      >
                        Approve User
                      </button>
                    </div>
                  </Modal>

                  <Modal
                    isOpen={deleteModal}
                    toggle={() => {
                      setDeleteModal(!deleteModal);
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                  >
                    <div className="modal-header">
                      <h5 className="modal-title" id="staticBackdropLabel">User Delete</h5>
                      <button type="button" className="btn-close"
                        onClick={() => {
                          setDeleteModal(false);
                        }} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                      <p>Are you sure you want to DELETE this user?</p>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => {
                        setDeleteModal(false);
                        }}
                      >
                        On 2nd thought, not yet.
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                          submitDelete()
                        }}
                      >
                        Delete User
                      </button>
                    </div>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Index);
