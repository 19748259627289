import React from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "../../assets/images/users/avatar-1.jpg"
import profileImg from "../../assets/images/profile-img.png"

const _d = {
  firstName: "Martin",
  newStuff: 7
}

const WelcomeComp = () => {
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">Hey {_d.firstName}!</h5>
                <p>{_d.newStuff > 0? "You have "+_d.newStuff+" new items to review" : "All good. Nothing new to review"}</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="12">
              <div className="pt-4">
                <Row>
                  <Col xs="2">
                    <h5 className="font-size-15">4</h5>
                    <p className="text-muted mb-0">Waitlist</p>
                  </Col>
                  <Col xs="2">
                    <h5 className="font-size-15">1</h5>
                    <p className="text-muted mb-0">Bounty</p>
                  </Col>
                  <Col xs="2">
                    <h5 className="font-size-15">1</h5>
                    <p className="text-muted mb-0">Dev Aid</p>
                  </Col>
                  <Col xs="2">
                    <h5 className="font-size-15">0</h5>
                    <p className="text-muted mb-0">Talent</p>
                  </Col>
                  <Col xs="2">
                    <h5 className="font-size-15">1</h5>
                    <p className="text-muted mb-0">Grants</p>
                  </Col>
                  <Col xs="2">
                    <h5 className="font-size-15">0</h5>
                    <p className="text-muted mb-0">Forum</p>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Link
                    to=""
                    className="btn btn-primary  btn-sm"
                  >
                    View Details <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
