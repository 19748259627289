import React, { useEffect, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap"

import classnames from "classnames"
import { Link } from "react-router-dom"

// XPS API Imports
import { _xps } from "@g7dao/public-project-webhooks";

const CreateQuest = () => {

  //meta title
  document.title="XPS | Quest Dashboard";

  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])
  const [xpsResponse, setXPSResponse] = useState({})
  const [getQuest, setQuest] = useState({
    name: "",
    description: "",
    instructions: "",
    ordered: 0,
    required: 0,
    featured: 0,
    xpEarned: 500,
    rankEarned: 0.10,
    category: "",
    subCategory: "",
    achievement: "",
    entryXp: 0,
    entryRank: 0.0,
    entryAchievement: "",
  });

  useEffect(() => {
    getAttributes();
  }, []);

  const getAttributes = () => {
    const resp = _xps.getAttributes();
    resp
      .then((r) => {
        if (r.status === 201 || r.status === 200) {
          return r.json();
        }
      })
      .then((r) => {
        const categories = [];
        const subCategories = [];

        const _d = r.data;
        for (let i in _d) {
          if (_d[i]["attribute"] === "category") {
            categories.push(_d[i])
          }
          if (_d[i]["attribute"] === "subCategory") {
            subCategories.push(_d[i])
          }
        }

        setCategoryList(categories)
        setSubCategoryList(subCategories)

      })
      .catch(() => {
        alert("get attribute error");
      });
  };

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }

      if (tab === 4) {
        processFormResults()
      }
    }
  }

  const processFormResults = () => {
    const resp = _xps.createQuest(getQuest);
    resp
      .then((r) => {
        if (r.status === 201 || r.status === 200) {
          return r.json();
        }
      })
      .then((r) => {setXPSResponse(r.data)})
      .catch(() => {
        alert("Form submission error");
      });
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <div className="text-wrap">
                {JSON.stringify(getQuest)}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">Create Quest Wizard</h4>
              <div className="wizard clearfix">
                <div className="steps clearfix">
                  <ul>
                    <NavItem
                      className={classnames({ current: activeTab === 1 })}
                    >
                      <NavLink
                        className={classnames({ current: activeTab === 1 })}
                        onClick={() => {
                          setactiveTab(1)
                        }}
                        disabled={!(passedSteps || []).includes(1)}
                      >
                        <span className="number">1.</span> Quest Details
                      </NavLink>
                    </NavItem>
                    <NavItem
                      className={classnames({ current: activeTab === 2 })}
                    >
                      <NavLink
                        className={classnames({ active: activeTab === 2 })}
                        onClick={() => {
                          setactiveTab(2)
                        }}
                        disabled={!(passedSteps || []).includes(2)}
                      >
                        <span className="number">2.</span> Quest Requirements
                      </NavLink>
                    </NavItem>
                    <NavItem
                      className={classnames({ current: activeTab === 3 })}
                    >
                      <NavLink
                        className={classnames({ active: activeTab === 3 })}
                        onClick={() => {
                          setactiveTab(3)
                        }}
                        disabled={!(passedSteps || []).includes(3)}
                      >
                        <span className="number">3.</span> Achievement Details
                      </NavLink>
                    </NavItem>
                    <NavItem
                      className={classnames({ current: activeTab === 4 })}
                    >
                      <NavLink
                        className={classnames({ active: activeTab === 4 })}
                        onClick={() => {
                          setactiveTab(4)
                        }}
                        disabled={!(passedSteps || []).includes(4)}
                      >
                        <span className="number">4.</span> Confirm Detail
                      </NavLink>
                    </NavItem>
                  </ul>
                </div>
                <div className="content clearfix">
                  <TabContent activeTab={activeTab} className="body">
                    <TabPane tabId={1}>
                      <Form>
                        <Row>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Quest Name
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter a memorable Quest Name"
                                value={getQuest.name || ""}
                                onChange={(e) => {
                                  setQuest((prevState) => {
                                    return { ...prevState, name: e.target.value };
                                  });
                                }}
                              />
                            </div>
                          </Col>
                          <Col lg="8">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input2">
                                Quest Description
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="basicpill-lastname-input2"
                                placeholder="Enter a short Quest Description"
                                value={getQuest.description || ""}
                                onChange={(e) => {
                                  setQuest((prevState) => {
                                    return { ...prevState, description: e.target.value };
                                  });
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-vatno-input6">
                                Select Category
                              </Label>
                              <select
                                className="form-select"
                                onChange={(e) => {
                                  setQuest((prevState) => {
                                    return { ...prevState, category: e.target.value };
                                  });
                                }}
                              >
                                <option defaultValue>
                                  Select a Category
                                </option>
                                {categoryList.map((q) => {
                                  return (
                                    <option value={q.id}>{q.name}</option>
                                  )
                                })}
                              </select>
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label for="basicpill-vatno-input6">
                                Select SubCategory (optional)
                              </Label>
                              <select
                                className="form-select"
                                onChange={(e) => {
                                  setQuest((prevState) => {
                                    return { ...prevState, subCategory: e.target.value };
                                  });
                                }}
                              >
                                <option defaultValue>
                                  No SubCategory
                                </option>
                                {subCategoryList.map((q) => {
                                  return (
                                    <option value={q.id}>{q.name}</option>
                                  )
                                })}
                              </select>
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-4">
                              <Label for="basicpill-servicetax-input8">
                                Ordered Tasks?
                              </Label>
                              <select
                                className="form-select"
                                onChange={(e) => {
                                  setQuest((prevState) => {
                                    return { ...prevState, ordered: +e.target.value };
                                  });
                                }}
                              >
                                <option defaultValue>
                                  require tasks to be completed in order?
                                </option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <div className="mb-3">
                              <Label for="basicpill-address-input1">
                                Detailed Instructions
                              </Label>
                              <textarea
                                id="basicpill-address-input1"
                                className="form-control"
                                rows="4"
                                placeholder="Enter Detailed Quest Instructions"
                                value={getQuest.instructions || ""}
                                onChange={(e) => {
                                  setQuest((prevState) => {
                                    return { ...prevState, instructions: e.target.value };
                                  });
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </TabPane>
                    <TabPane tabId={2}>
                      <div>
                        <Form>
                          <Row>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-phoneno-input3">
                                  Min Rank
                                    <Link to="#" className="m-1" id="m-r">
                                      <i class="fas fa-comment-dots"></i>
                                      <UncontrolledTooltip placement="top" target="m-r">
                                        minimum rank required to start quest
                                      </UncontrolledTooltip>
                                    </Link>
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-phoneno-input3"
                                  placeholder="1.0.0"
                                  value={getQuest.entryRank || 0.0}
                                  onChange={(e) => {
                                    setQuest((prevState) => {
                                      return { ...prevState, entryRank: +e.target.value };
                                    });
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-email-input4">
                                  Min XP
                                    <Link to="#" className="m-1" id="m-xp">
                                      <i class="fas fa-comment-dots"></i>
                                      <UncontrolledTooltip placement="top" target="m-xp">
                                        minimum XP required to start quest
                                      </UncontrolledTooltip>
                                    </Link>
                                </Label>
                                <Input
                                  type="email"
                                  className="form-control"
                                  id="basicpill-email-input4"
                                  placeholder="1000"
                                  value={getQuest.entryXp || 0}
                                  onChange={(e) => {
                                    setQuest((prevState) => {
                                      return { ...prevState, entryXp: +e.target.value };
                                    });
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-phoneno-input3">
                                  Earned Rank
                                    <Link to="#" className="m-1" id="e-r">
                                      <i class="fas fa-comment-dots"></i>
                                      <UncontrolledTooltip placement="top" target="e-r">
                                        rank earned by completing quest
                                      </UncontrolledTooltip>
                                    </Link>
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-phoneno-input3"
                                  placeholder="0.1.0"
                                  value={getQuest.rankEarned || 0}
                                  onChange={(e) => {
                                    setQuest((prevState) => {
                                      return { ...prevState, rankEarned: +e.target.value };
                                    });
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-email-input4">
                                  Earned XP
                                    <Link to="#" className="m-1" id="e-xp">
                                      <i class="fas fa-comment-dots"></i>
                                      <UncontrolledTooltip placement="top" target="e-xp">
                                        XP earned by completing quest
                                      </UncontrolledTooltip>
                                    </Link>
                                </Label>
                                <Input
                                  type="email"
                                  className="form-control"
                                  id="basicpill-email-input4"
                                  placeholder="100"
                                  value={getQuest.xpEarned || 0}
                                  onChange={(e) => {
                                    setQuest((prevState) => {
                                      return { ...prevState, xpEarned: +e.target.value };
                                    });
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-cstno-input7">
                                  Quest Featured?
                                </Label>
                                <select
                                  className="form-select"
                                  onChange={(e) => {
                                    setQuest((prevState) => {
                                      return { ...prevState, featured: +e.target.value };
                                    });
                                  }}
                                >
                                  <option defaultValue>
                                    Is this quest featured?
                                  </option>
                                  <option value="0">No</option>
                                  <option value="1">Yes</option>
                                </select>
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-cstno-input7">
                                  Quest Required?
                                </Label>
                                <select
                                  className="form-select"
                                  onChange={(e) => {
                                    setQuest((prevState) => {
                                      return { ...prevState, required: +e.target.value };
                                    });
                                  }}
                                >
                                  <option defaultValue>
                                    Is this quest required?
                                  </option>
                                  <option value="1">Yes</option>
                                  <option value="0">No</option>
                                </select>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </TabPane>
                    <TabPane tabId={3}>
                      <div>
                        <Form>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-vatno-input6">
                                  Achievement Earned
                                    <Link to="#" className="m-1" id="ache">
                                      <i class="fas fa-comment-dots"></i>
                                      <UncontrolledTooltip placement="top" target="ache">
                                        achievement earned by completing this task
                                      </UncontrolledTooltip>
                                    </Link>
                                </Label>
                                <select
                                  className="form-select"
                                  onChange={(e) => {
                                    setQuest((prevState) => {
                                      return { ...prevState, achievement: e.target.value };
                                    });
                                  }}
                                >
                                  <option defaultValue>
                                    Select Achievement
                                  </option>
                                  <option value="12321488745">Super Awesome Person</option>
                                  <option value="12321445555">Onboarding Champion</option>
                                </select>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </TabPane>
                    <TabPane tabId={4}>
                      <div className="row justify-content-center">
                        <Col lg="6">
                          <div className="text-center">
                            <div className="mb-4">
                              <i className="mdi mdi-check-circle-outline text-success display-4" />
                            </div>
                            <div>
                              <h5>Completion Detail</h5>
                              <p className="text-muted">
                                TODO: We will add the requirements needed to complete this quest here
                              </p>
                            </div>
                            <div>
                              <h5>Quest Submission Details</h5>
                              <p className="text-muted">
                                {JSON.stringify(xpsResponse)}
                              </p>
                            </div>
                          </div>
                        </Col>
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
                <div className="actions clearfix">
                  <ul>
                    <li
                      className={
                        activeTab === 1 ? "previous disabled" : "previous"
                      }
                    >
                      <Link
                        to="#"
                        onClick={() => {
                          toggleTab(activeTab - 1)
                        }}
                      >
                        Previous
                      </Link>
                    </li>
                    <li
                      className={activeTab === 4 ? "next disabled" : "next"}
                    >
                      <Link
                        to="#"
                        onClick={() => {
                          toggleTab(activeTab + 1)
                        }}
                      >
                        Next
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CreateQuest;
